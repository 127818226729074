
import { mapGetters, mapActions } from 'vuex';
import { Inventory, Warehouse } from '@/models/inventory';
import { ErrorManager } from '@/models/error';
import ErrorAlert from '@/components/common/ErrorAlert.vue';
import AuthMixin from '@/mixins/auth';

import { DataTableHeader } from 'vuetify';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import InventoryList from '@/components/inventory/InventoryList.vue';

export default AuthMixin.extend({
  components: { ErrorAlert, ConfirmationDialog, InventoryList },
  name: 'WarehouseDetail',

  data() {
    return {
      loading: false,

      title: 'Warehouse',
      errorMessage: '',
      detailErrorMessage: '',

      deleteInProgress: false,
      deleteWarehouseDialog: false,
      errorWarehouseDialogMessage: '',

      search: ''
    };
  },

  computed: {
    ...mapGetters({
      getWarehouseById: 'warehouseStore/getWarehouseById',
      inventories: 'inventoryStore/getInventories'
    }),

    warehouseId(): number {
      return Number(this.$route.params.warehouseId);
    },

    warehouse(): Warehouse {
      return this.getWarehouseById(this.warehouseId);
    },

    warehouseInitials(): string {
      return (
        this.warehouse.name
          ?.toUpperCase()
          .split(' ')
          .map((n) => n[0])
          .join('')
          .trim() ?? ''
      );
    },

    canEditWarehouse(): boolean {
      return this.isUserOrganizationAdmin || this.isUserSupervisor;
    },

    warehouseInventories(): Inventory[] {
      return this.inventories.filter((inventory: Inventory) => {
        return inventory.warehouse?.id == this.warehouseId;
      });
    },

    inventoryHeaders(): DataTableHeader<any>[] {
      const headerList: DataTableHeader<any>[] = [
        {
          text: 'Item #',
          value: 'item.identifier',
          width: '90px'
        },
        {
          text: 'Item Name',
          value: 'item.name',
          width: '120px'
        },
        {
          text: 'Description',

          sortable: false,
          value: 'item.description'
        },
        {
          text: 'Location',
          value: 'location',
          width: '250px'
        },
        {
          text: 'Quantity',
          sortable: false,
          width: '20',
          value: 'quantity'
        }
      ];

      if (this.isUserEmployee) {
        headerList.push({
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }); //adds actions column
      }
      return headerList;
    }
  },

  methods: {
    ...mapActions({
      fetchWarehouses: 'warehouseStore/fetchWarehouses',
      deleteWarehouse: 'warehouseStore/deleteWarehouse',
      fetchInventories: 'inventoryStore/fetchInventories'
    }),

    clearAllErrors(): void {
      this.errorMessage = '';
      this.detailErrorMessage = '';
    },

    clearAllDialogErrors() {
      this.errorWarehouseDialogMessage = '';
    },
    openWarehouseDeleteDialog() {
      this.deleteWarehouseDialog = true;
    },

    closeWarehouseDeleteDialog() {
      this.deleteInProgress = false;
      this.deleteWarehouseDialog = false;
      this.clearAllDialogErrors();
    },

    destroyWarehouse(warehouse: Warehouse) {
      this.deleteInProgress = true;
      this.deleteWarehouse(warehouse.id)
        .then(() => {
          this.closeWarehouseDeleteDialog();
        })
        .catch((error) => {
          this.errorWarehouseDialogMessage =
            ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.deleteInProgress = false;
        });
    },

    openWarehouseDetailDialog(isEdit = false, instance: Warehouse) {
      (this as any).$refs.warehouseDialog.open(isEdit, instance);
    },

    openInventoryTableDialog() {
      const inventoryList = this.$refs.inventoryList as InstanceType<
        typeof InventoryList
      >;
      inventoryList.openInventoryTableDialog();
    }
  },

  mounted() {
    // only fetch requisition if not available in store ("try-get-or-fetch")
    if (!this.warehouse) {
      this.loading = true;

      try {
        this.fetchWarehouses(this.warehouseId);
        this.fetchInventories(this.warehouseId);
      } catch (error) {
        if (error.response) {
          if (error.response.data.detail) {
            this.detailErrorMessage = error.response.data.detail;
          }
        }
        this.errorMessage = ErrorManager.extractApiError(error as any);
      } finally {
        this.loading = false;
      }
    }
  }
});
